import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { AuthLayout } from '../src/components/AuthLayout';
import { Overview } from '../src/components/Overview/Overview';

const Index = () => {
  return (
    <AuthLayout>
      <Head>
        <title>Spocket - Sell Your Products to Awesome Retailers</title>
      </Head>
      <Overview />
    </AuthLayout>
  );
};

// sample of how server-side pages should be treated for working with i18n.
// Also see https://github.com/isaachinman/next-i18next#4-declaring-namespace-dependencies
// And https://github.com/isaachinman/next-i18next/issues/1040#issuecomment-796476999
export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'overview']))
  }
});

export default Index;

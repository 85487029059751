import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';

import { media } from '../../utils/media';
import { playlistResources, bookmarksResources } from '../../const/common';
import { Icon } from '../common/icons/Icon';

const WhiteCard = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 12px;
  color: ${(props) => props.theme.colors.grey90};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
  padding: 20px;
  width: 100%;
`;

const Tabs = styled(WhiteCard)`
  font-family: Avenir;
  width: 100%;
  display: flex;
  box-shadow: unset;

  ${media.SMALL_DESKTOP`
    display:block;
  `}
`;

const TabList = styled.ul`
  max-width: 368px;
  min-width: 368px;
  list-style-type: none;
  padding-left: 15px;

  ${media.SMALL_DESKTOP`
    max-width: 100%;
    min-width: 100%;
    padding-left: unset;
  `}
`;

const TabListHeader = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.black15};
`;

const TabItem = styled.li`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.grey90};
  padding: 7px 14px;
  position: relative;
  background-color: ${(props) => (props.isActive ? props.theme.colors.purple08 : 'unset')};

  img {
    margin-right: 10px;
  }

  :hover {
    background-color: ${(props) => props.theme.colors.purple08};
  }

  :before {
    content: '';
    top: 0;
    left: 0;
    width: 4px;
    position: absolute;
    height: 100%;
    background-color: ${(props) => (props.isActive ? props.theme.colors.purple40 : 'unset')};
  }
`;

const TabContent = styled.div`
  width: 100%;
  overflow: hidden;
  padding-bottom: 34%;
  position: relative;
  height: 0;

  ${media.SMALL_DESKTOP`padding-bottom: 56.25%;`}

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

// https://css-tricks.com/fluid-width-video/#iframe-video-youtube-vimeo-etc
const VideoResponsive = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const Bookmarks = styled.div`
  font-family: Avenir;
  margin: 22px 0 186px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;

  a {
    text-decoration: none;
  }

  ${media.TABLET`grid-template-columns: 1fr; grid-template-rows: 1fr 1fr 1fr;`};
`;

const Bookmark = styled(WhiteCard)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.TABLET`align-items: center;`}
`;

const BookmarkHeader = styled.h2`
  font-size: 24px;
  line-height: 33px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.black15};
`;

const BookmarkDescription = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.grey80};
`;

export const Overview = () => {
  const { t } = useTranslation(['overview']);
  const [currentTab, setCurrentTab] = useState(0);
  const tab = playlistResources[currentTab];

  return (
    <>
      <Tabs>
        <TabContent>
          <VideoResponsive
            data-testid={tab.videoId}
            title={tab.title}
            src={tab.videoSrc}
            frameBorder="0"
          />
        </TabContent>

        <TabList>
          <TabListHeader>{t('overview:tutorial_playlist.title')}</TabListHeader>

          {playlistResources.map((playlist, tabIndex) => {
            const { title } = playlist;
            const isTabActive = currentTab === tabIndex;
            return (
              <TabItem
                onClick={() => setCurrentTab(tabIndex)}
                isActive={isTabActive}
                aria-selected={isTabActive}
                key={title}>
                <Icon src="assets/icons/play-purple-icon.svg" alt="play icon" />
                {t(title)}
              </TabItem>
            );
          })}
        </TabList>
      </Tabs>

      <Bookmarks>
        {bookmarksResources.map((bookmark) => {
          const { imageSrc, imageAlt, title, description, link, linkId } = bookmark;
          return (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              data-testid={linkId}
              key={title}>
              <Bookmark>
                <Icon src={imageSrc} alt={imageAlt} />
                <BookmarkHeader>{t(title)}</BookmarkHeader>
                <BookmarkDescription>{t(description)}</BookmarkDescription>
              </Bookmark>
            </a>
          );
        })}
      </Bookmarks>
    </>
  );
};
